import COS from "cos-js-sdk-v5";
import {
	getCosConfig
} from "@/service/auth.js";

export default {
	data() {
		return {
			cosSecret: {
				// TmpSecretId: "AKIDARR8Q0kT0hvaxooDHltdvwYOojgOF7DC",
				// TmpSecretKey: "CTPUp1rdxDZEUXL48GX6A2FBbcovFjmw",
				// SecurityToken: "ad9116513dbfd6bc9d51595bd895bcd0463cc20430001",
				// StartTime: 1646879191,
				// ExpiredTime: 1646880993,
			},
			loading: false,
			cos: null,
		};
	},
	created() {
		this.initCos();
	},
	methods: {
		// 初始化腾讯云对象存储实例
		async initCos() {
			let res = await getCosConfig();
			this.cosSecret = res;
			this.cos = new COS({
				getAuthorization: async (options, callback) => {
					callback({
						TmpSecretId: this.cosSecret && this.cosSecret.TmpSecretId,
						TmpSecretKey: this.cosSecret && this.cosSecret.TmpSecretKey,
						XCosSecurityToken: this.cosSecret && this.cosSecret.SecurityToken,
						StartTime: this.cosSecret && this.cosSecret.StartTime,
						ExpiredTime: this.cosSecret && this.cosSecret.ExpiredTime,
					});
					// }
				},
			});
		},
		// beforeUpload(file) {
		//   const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
		//   const isLt2M = file.size / 1024 / 1024 < 2

		//   if (!isImage) {
		//     ElMessage.error('上传图片只能是JPG或PNG格式!')
		//     return false
		//   }
		//   if (!isLt2M) {
		//     ElMessage.error('上传图片大小不能超过 2MB!')
		//     return false
		//   }
		//   this.loading = true
		// },
		// 文件上传到腾讯COS
		async uploadTecent(file) {
			// 文件上传之前的一些处理
			// 生成文件key
			return new Promise((resolve, reject) => {
				let key = this.getTempFileKey(file.name);
				this.cos.putObject({
						Bucket: this.cosSecret.Bucket,
						Region: this.cosSecret.Region,
						Key: key,
						Body: file,
						onProgress: progressData => {
							this.uploading && this.uploading(progressData);
						},
					},
					(err, data) => {
						this.loading = false;
						if (err) {
							this.$message.error("cos_error:" + (err || "上传错误！"));
							reject(err);
						} else {
							const url = key;
							resolve(url);
						}
					}
				);
			});
		},
		getTempFileKey(fileName) {
			const now = new Date();
			return `/images/${now.getTime()}${fileName}`;
		},
	},
};